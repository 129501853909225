import React from 'react'
import '../styles/section-2.scss'

//images
import splat3 from '../images/splat3.svg'

import bacon from '../images/bacon.png'
import egg from '../images/egg.png'
import mushroom from '../images/mushroom.png'
import sausage from '../images/sausage.png'
import toast from '../images/toast.png'
import tomato from '../images/tomato.png'

const Section2 = () => {

    return (
        <section id='section-2'>

            <div className='text-container'>
              
              <p className='text-2'>All the salty savoury goodness of a greasy spoon in one box!</p>

              <div className='food-container'>
                  <div className='food-item'>
                      <p>Tomato!</p>
                      <img className='tomato' src={tomato} alt="Tomato" />
                  </div> 

                  <div className='food-item'>
                      <p>Bacon!</p>
                      <img className='bacon' src={bacon} alt="Bacon" />
                  </div> 

                  <div className='food-item'>
                      <p>Mushroom!</p>
                      <img className='mushroom' src={mushroom} alt="Mushroom" />
                  </div> 

                  <div className='food-item'>
                      <p>Sausage!</p>
                      <img className='sausage' src={sausage} alt="Sausage" />
                  </div> 

                  <div className='food-item'>
                      <p>Egg!</p>
                      <img className='egg' src={egg} alt="Egg" />
                  </div> 

                  <div className='food-item'>
                      <p>Toast!</p>
                      <img className='toast' src={toast} alt="Toast" />
                  </div>                                                                                                          
              </div>
            </div>

            <div className='splat-container'>
                {/* <div className='splat-text-container splat-1'>                    
                    <img src={splat1} alt="Bored of egg bennies and aÇai bowls?" />
                </div>

                <div className='splat-text-container splat-2'>
                    <img src={splat2} alt="Fave Caff become an overpriced instagram hotspot?" />
                </div>   */}

                <div className='splat-text-container splat-3'>
                    <img src={splat3} alt="Too Hungover to Cook?, Then grab yourself a box of delicious caff crunch" />
                </div>                 
            </div>

        </section>
    )
}

export default Section2