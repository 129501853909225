import React from 'react'
import '../styles/section-4.scss'

//images
import image1 from '../images/gallery/1.jpeg';

import image3 from '../images/gallery/3.jpeg';
import image4 from '../images/gallery/4.jpeg';
import image5 from '../images/gallery/5.jpeg';
import image6 from '../images/gallery/6.jpeg';

import image8 from '../images/gallery/8.jpeg';


const Section4 = () => {

    return (
        <section id='section-4'>

            <div className='gallery-container'>
                <img src={image1} alt="Box of Caff Crunch Cereal on a Cafe table" />                
                <img src={image3} alt="Box of Caff Crunch cereal, bowl of cereal and an Uber Eats bag" />
                <img src={image4} alt="Top view of a bowl of Caff Crunch cereal surrounded by a full english breakfast and the Caff Crunch box" />
                <img src={image5} alt="Caff crunch cereal box in a supermarket" />
                <img src={image6} alt="A table with two full english breakfasts' and a bowl of Caff Crunch" />                
                <img src={image8} alt="Box of Caff Crunch cereal and a bowk of the cereal" />
            </div>

        </section>
    )
}

export default Section4