import React, { useRef, useEffect } from 'react'
import '../styles/section-1.scss'

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//images
import logo from '../images/logo.svg'
import builder from '../images/builder.png'
import tape from '../images/tape.svg'

gsap.registerPlugin(ScrollTrigger);

const Section1 = ({ sectionLoaded }) => {
    const canvasRef = useRef(null);  
    const frameCount = 61;
    let imageLoadedCount = 0;
    let imagesLoaded = false;

    const checkImagesLoaded = () => {
        imageLoadedCount++;

        if(imageLoadedCount >= frameCount && !imagesLoaded) {
            imagesLoaded = true;
            imageLoadedCount = 0;
            initIntroAnimation();
        }
    };

    const initIntroAnimation = () => {
        const tlIntro = gsap.timeline({ onComplete: () => {
            //callback to parent
            if(typeof sectionLoaded === 'function') sectionLoaded();            
        } });

        tlIntro.addLabel('start')
        .to('.main-logo', { duration: 1, autoAlpha: 1, scale: 1}, 'start+=0.1')
        .to('canvas', { duration: 1, autoAlpha: 1}, 'start+=0.1');
        
    };

    const initScrollAnimation = () => {

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = (window.innerHeight * 1.777);
        canvas.height = window.innerHeight;

        const currentFrame = (index) => (
          `/images/scroller/${(index + 1).toString()}.png`
        );
                  
        const images = [];          

        const animation = {
            frame: 0
        };

        for (let i = 0; i < frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            img.onload = checkImagesLoaded;
            images.push(img);
        }         

        function renderImageToCanvas() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[animation.frame], (canvas.width - (canvas.height * 1.777)) / 2, 0, (canvas.height * 1.777), canvas.height); 
        }        
        
        //gsap definitions
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#section-1",
              start: "top top",
              end: "+=2000",              
              pin: true,
              scrub: 0.1
            }
        });

        tl.addLabel('start')
        .to(animation, {
          frame: frameCount - 1,
          snap: 'frame',
          ease: 'none',
          onUpdate: renderImageToCanvas
        })
        .to('.down-chevron', { autoAlpha: 0}, 'start+=0.1')
        .to('.tape', { y: '-100%'}, 'start+=0.1')
        .to('.main-logo', { y: '-100%'}, 'start+=0.1')
        .to('.builder', { top: '20%'}, 'start+=0.1')        
        .set({}, {}, "+=0.1")      
        .addLabel('sequence-end');

        tl.to('.canvas', { x: '-100%'}, 'sequence-end') 
        .set({}, {}, "+=0.1") 
        .to('.speech-bubble', { autoAlpha: 1, scale: 1})
        .set({}, {}, "+=0.1");  
    
    };



    useEffect(() => {
        let ctx = gsap.context(() => {
            initScrollAnimation();
        });

        return () => ctx.revert();
    }, [])        

    return (
        <section id='section-1'>
            <img className='tape' src={tape} alt="Hazard Tape" />               
            <img className='main-logo' src={logo} alt="Caff Crunch Logo" />

            <div className='down-chevron'>
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#f7ed55" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
            </div>

            <img className='builder' src={builder} alt="Cartoon builder giving a thumbs up" />

            <div class='speech-bubble'>
                <p>Yes, Geezer! Say good morning to the world's first fry-up flavoured cereal!</p>
            </div>

            <canvas className='canvas' ref={canvasRef} />

        </section>
    )
}

export default Section1