import React, { useEffect } from 'react';
import '../styles/globals.scss';

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//images
import bolt from '../images/bolt.svg'

gsap.registerPlugin(ScrollTrigger);

function Terms() {

    const initAnimation = () => {

        gsap.timeline({
          scrollTrigger:{
            trigger: "body",
            scrub:0.2,
            start: 'top top',
            end:'bottom bottom',
          }
        })
        .to('.bolt', {
          rotation:360*5,
          duration:1, ease:'none',
        });
        
    
    };

    useEffect(() => {
        let ctx = gsap.context(() => {
            initAnimation();
        });

        return () => ctx.revert();
    }, []);   


    return (
        <main class='terms'>
                <h1>Website Terms of Use</h1>
                
                <h3>1.  Introduction</h3>

                <p>1.1  Welcome to our website (<b>“Website”</b>) which is operated by PBPMLL0012 LIMITED (<b>“we” “us” “our”</b>). whose registered office is at Biscuit Building, 10 Redchurch Street, London, E2 7DD.</p>

                <p>1.2  Access to and use of this Website is subject to the following terms, conditions and notices (the <b>“Terms“</b>). Please read these Terms carefully and alongside our <a href='/privacy'>Privacy Policy.</a></p>
            
                <p>1.3  Please note: Clause 11.2 of these Terms contains an arbitration clause and class action waiver that applies to all claims brought against us in the United States. Please read it carefully as it affects how disputes with us are resolved.</p>

                <p>1.4  By using our Website you agree to be legally bound by these Terms. If you do not agree to accept these Terms, you should not use our Website.</p>

                <h3>2.  Access and use of the website</h3>

                <p>2.1  Access to our Website is provided on a temporary basis for your personal and non-commercial use and we reserve the right to suspend, withdraw, discontinue or change any part of our Website, including the availability of any content, without notice. We will not be liable to you if for any reason our Website is unavailable at any time or for any period.</p>
            
                <p>2.2  The content on our Website is provided for information only. Although we make reasonable efforts to update the information on our Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete or up-to-date.</p>

                <p>2.3  In accessing any part of our Website, you agree not to:<br /><br />
                    use our Website in such a way that disrupts, interferes with or restricts the use of our Website by other users;<br /><br />
                    use our Website for any activities which breach any laws or regulations or infringe any third party rights;<br /><br />
                    use the personal information of another person in order to access or use our Website;<br /><br />
                    remove, obscure, or alter any copyright notices, trademarks, or other proprietary rights notices of ours or any third party;<br /><br />
                    decompile, reverse engineer, disassemble, copy or adapt any software or other code or scripts forming part of our Website; and<br /><br />
                    transmit any viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful through our Website.
                    </p>

                <h3>3.  Our content</h3>
                <p>3.1  Our content, trademarks, copyright, patents, logos, domain names and other related intellectual property rights or other features of our brand or our clients’ brand(s) belong to us or to our clients or licensors. Your use of our Website does not grant you any rights in our and/or our licensors intellectual property whether for commercial or non-commercial use. By using the Website, you agree not to copy, distribute, modify or make derivative works of any materials on the Website without the prior written consent of the owner of such materials.</p>

                <p>3.2  We do not accept any unsolicited ideas to this Website including without limitation suggestions about advertising or promotions, or merchandising of any products, additions to our services, or changes in methods of doing business. We may already be working on or may in the future work on a similar idea. This policy eliminates concerns about ownership of such ideas. If, notwithstanding this policy, you submit an unsolicited idea to this Website, you understand and acknowledge that such idea is not submitted in confidence and we assume no obligation, expressed or implied, by considering it. You further understand that we shall exclusively own all known or hereafter existing rights to the idea everywhere in the world, and that such idea is hereby irrevocably assigned to us. Without limiting the foregoing, to the extent any such assignment is deemed unenforceable, and or to the extent necessary for us to utilise your submission, you hereby grant to us an irrevocable, perpetual, world-wide license to use the idea and any associated intellectual property in any manner, in any medium now known or hereafter developed, without compensation to you.</p>

                <h3>4.  Third party websites</h3>
                <p>4.1  Our Website may include links to third party websites which are controlled and maintained by others. These links are provided for information and convenience and we have no control over and cannot therefore accept responsibility or liability for any content on any linked third party website. We do not endorse any linked website.</p>

                <p>4.2  Your use of any third party internet website will be subject to that website’s own terms of use, rules and policies, and not these Terms. It is your responsibility to become familiar and comply with such third party website terms and conditions and any other policies of such websites.</p>

                <h3>5.  Privacy</h3>

                <p>We may collect certain personal and analytical information from you, among other ways, through the Website. We will handle such information in accordance with our <a href='/privacy'>Privacy Policy.</a></p>

                <h3>6.  Disclaimer</h3>

                <p><b>The website and all content are provided “as is” and without any warranty or representation of any kind, whether express or implied (including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose). Without limiting the generality of the immediately preceding sentence, to the fullest extent permissible by law, we do not warrant or represent that the website or any content on the website: (a) will always be available for use, (b) are free from spyware, malware, adware, viruses, worms or other malicious code or other harmful components, (c) will meet your requirements, (d) do not infringe the intellectual property rights of a third party, or (e) are error-free or that any defects therein will be corrected.</b></p>

                <h3>7.  Our liability to you</h3>

                <p><b>7.1  Subject to clause 7.3, to the maximum extent permitted by law your sole and only remedy for any problems, issues or concerns arising from the use of our website is to stop using our website.</b></p>

                <p><b>7.2  Without prejudice to the generality of clause 7.1, you agree that; (1) in the event that clause 7.1 is not binding on you, our maximum liability shall be limited to direct damages only up to a maximum sum of £100 sterling; and (2) we shall not have any liability for incidental, special, indirect, consequential or other damages whatsoever (including, but not limited to, damages for loss of profits, loss of data, or computer crashes or other denials of services) arising from or related to your use of, or inability to use, the website site, or any content thereon, however caused, regardless of the theory of liability (including, but not limited to, breach of contract, tort, (including negligence) or other), and even if we knew, or should have known, of the possibility of such damages.</b></p>

                <p>7.3  We do not exclude our liability (if any) to you for: (1) personal injury or death resulting from our negligence; (2) fraud or fraudulent misrepresentation; or (3) any matter for which it would be illegal for us to exclude, or to attempt to exclude, our liability.</p>

                <h3>8.  Your liability to us</h3>

                <p>You agree to indemnify and hold us, our subsidiaries, and affiliates, and their respective officers, agents, partners and employees, harmless from any loss, liability, demand, claim or legal proceedings brought or threatened arising out of or in connection with: (1) your misuse of this Website; (2) breach of these Terms; and/or (3) any violation by you of applicable laws.</p>

                <h3>9.  Information provided by you</h3>
                <p>You grant to us a non-exclusive, perpetual and fully-paid license to copy, distribute, modify, and create derivative works of all information and other content submitted by you to us through the Website. All information provided to you through the Website must be accurate and complete in all respects, unless by its nature such information is not intended to be accurate or complete.</p>

                <h3>10.  General provisions and Jurisdiction</h3>
                <p>10.1  If any provision of these Terms is found to be invalid for any reason, the invalidity of that provision will not affect the remaining provisions of these Terms, which will remain in full force and effect.</p>

                <p>10.2  We may amend these Terms at any time, so please ensure that you check them frequently. By continuing to use any of our Website after changes are made, you are accepting those changes and will be bound by them.</p>

                <p>10.3  These Terms constitute the entire agreement between us and you regarding use of the Website.</p>

                <p>10.4  Failure by either of us to exercise any right or remedy under these Terms does not constitute a waiver of that right or remedy.</p>

                <h3>11.  Governing law and jurisdiction</h3>

                <p><b>11.1  If you are not a resident of the United States:</b> Any dispute or claim arising out of or in connection with our Website and/or these Terms (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales and will be subject to the exclusive jurisdiction of the English courts. This does not affect your statutory rights in your home jurisdiction.</p>

                <p><b>11.2  If you are a resident of the United States: </b>The laws of the State of New York shall govern these Terms. While we will make reasonable efforts to resolve any disagreements you may have with us, if these efforts fail you agree that all claims, disputes or controversies against us arising out of these Terms or any services provided by us (“Claims”) shall be exclusively submitted to binding arbitration (except for matters that may be taken to small claims court), no matter what legal theory they are based on or what remedy (damages, or injunctive or declaratory relief) they seek. This includes Claims based on contract, tort (including intentional tort), fraud, agency, your or our negligence, statutory or regulatory provisions, or any other sources of law; Claims made as counterclaims, cross-claims, third- party claims, interpleaders or otherwise; and Claims made independently or with other claims. The party filing arbitration must submit Claims to the American Arbitration Association and follow its rules and procedures for initiating and pursuing arbitration. Any arbitration hearing that you attend will be held at a place chosen by the American Arbitration Association in the same city as the U.S. District Court closest to your then current residential address, or at some other place to which you and we agree in writing, and the arbitrator shall apply New York law consistent with the Federal Arbitration Act. <b>You shall not be entitled to join or consolidate claims in arbitration by or against other users or to arbitrate any claim as a representative or member of a class or in a private attorney general capacity. </b>You may obtain copies of the current rules, and forms and instructions for initiating arbitration by contacting the American Arbitration Association at using the contact information noted below.<br /><br />
                American Arbitration Association<br /><br />
                800-778-7879 (toll-free)<br /><br />
                Website: <a href='https://www.adr.org'>www.adr.org</a>
                </p>

                <p>11.3  A single, neutral arbitrator will resolve Claims. The arbitrator will be either a lawyer with at least ten (10) years’ experience or a retired or former judge, selected in accordance with the rules of the American Arbitration Association. The arbitration will follow the procedures and rules of the American Arbitration Association which are in effect on the date the arbitration is filed unless those procedures and rules are inconsistent with these Terms, in which case these Terms will prevail. Those procedures and rules may limit the discovery available to you or us. The arbitrator will take reasonable steps to protect your customer account information and other confidential information if requested to do so by you or us. Each party to the arbitration will bear the expense of that party’s attorneys, experts, and witnesses, and other expenses, regardless of which party prevails, but a party may recover any or all expenses from another party if the arbitrator, applying applicable law, so determines. The arbitrator’s award is final and binding on the parties.</p>

                <p><b>11.4  Any claim you have must be commenced within one (1) year after the date the claim arises.  As noted above, you and we hereby voluntarily and knowingly waive any right either may have to a jury trial.</b></p>

                <p>11.5  We will not choose to arbitrate any Claim you bring in small claims court. However, if such a Claim is determined by the court to be outside its jurisdiction, the parties agree that the dispute shall then be submitted to arbitration.</p>

                <h2>OTHER INFORMATION</h2>

                <p>In these Terms, the following words and expressions have the following meanings:<br /><br />
                    <b>Our content</b> means any text, files, images, photos, graphics, video, sounds, musical works or any other materials that we post on or through the Website.<br /><br />
                    <b>Third party content</b> any text, files, images, photos, graphics, video, sounds, musical works or any other materials posted on or through the Website by our third party partners.<br /><br />
                    <b>You, your, yours</b> means you as a user of any Website that hosts these Terms.<br /><br />
                    <b>Last updated: December 2023</b></p>

                <img className='bolt bolt-1' src={bolt} alt="Bolt Head" />
                <img className='bolt bolt-2' src={bolt} alt="Bolt Head" />
                <img className='bolt bolt-3' src={bolt} alt="Bolt Head" />
                <img className='bolt bolt-4' src={bolt} alt="Bolt Head" />                       
            </main>
    );
}

export default Terms