import React from 'react'
import '../styles/footer.scss'

const Footer = () => {

    return (
        <footer>
            <a href='/terms'>Terms</a>
            <a href='/privacy'>Privacy</a>
        </footer>
    )
}

export default Footer