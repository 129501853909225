import React, { useRef } from 'react'

import '../styles/globals.scss';

import Section1 from "../components/section-1";
import Section2 from "../components/section-2";
import Section3 from "../components/section-3";
import Section4 from "../components/section-4";
import Footer from "../components/footer";

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//images
import bolt from '../images/bolt.svg'

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const pageContainer = useRef(null);

  const initAnimation = () => {

    gsap.timeline({
      scrollTrigger:{
        trigger: "body",
        scrub:0.2,
        start: 'top top',
        end:'bottom bottom',
      }
    })
    .to('.bolt', {
      rotation:360*5,
      duration:1, ease:'none',
    });
    

};

  const hideLoader = () => {
    //artificial 1s delay minimum as loading is pretty snappy
    setTimeout(()=> {
      
      pageContainer.current.style = {}
      initAnimation();
    }, 1000);
  }

  return (
    <main ref={pageContainer} style={{height: '0px', overflow: 'hidden'}}>

      <Section1 sectionLoaded={hideLoader} />
      <Section2 />
      <Section4 />
      <Section3 />
      <Footer />
      
      <img className='bolt bolt-1' src={bolt} alt="Bolt Head" />
      <img className='bolt bolt-2' src={bolt} alt="Bolt Head" />
      <img className='bolt bolt-3' src={bolt} alt="Bolt Head" />
      <img className='bolt bolt-4' src={bolt} alt="Bolt Head" />

      {/* <Loader isLoading={isLoading}/> */}

    </main>
  )
}

export default Home